import { PageQoModel } from '@/models/common/CommonModel'
export interface HttpLogModelBase {
  contextId: string;
  clientIp: string;
  requestType: string;
  uri: string;
  queryString?: string;
  access?: boolean;
  principalNo?: string;
}

export interface HttpLogQo extends HttpLogModelBase {
  timeStampGt?: number,
  timeStampLt?: number,
  page?: PageQoModel;
}

export const HttpLogQoData = (): HttpLogQo => {
  return {
    contextId: '',
    clientIp: '',
    requestType: '',
    uri: ''
  }
}

export interface HttpLogModel extends HttpLogModelBase {
  timeStamp?: number;
  finishTimeStamp?: number;
  params?: any;
  requestBody?: any;
}

export const HttpLogData: HttpLogModel = {
  contextId: '',
  clientIp: '',
  requestType: '',
  uri: '',
  access: false,
  timeStamp: 0,
  finishTimeStamp: 0
}


