























































































import DateUtil from 'global-ui/packages/utils/DateUtil';
import { HttpLogDelete, HttpLogPage } from '@/api/logs/LogApi';
import page from '@/mixins/page';
import Component, { mixins } from 'vue-class-component';
import { HttpLogModel, HttpLogQo, HttpLogQoData } from '@/models/logs/HttpLogModel';
import { JSONView } from 'vue-json-component';

@Component({
  name: 'BackEndLog',
  components: { 'json-view': JSONView }
})
export default class RequestLog extends mixins(page) {
  DateUtil: any = DateUtil;
  loadingOuter: boolean = false;
  logSearchModel = HttpLogQoData();
  logListData: HttpLogModel[] = [];
  deleteDate: Date | null = null;
  // 请求体显示标签
  dialogVisible: boolean = false;
  // 当前选择的requestBody
  requestBodyJson: any = '';

  created() {
    this.logSearchModel.contextId = this.$route.query.contextId as string;
    this.getHttpLogPage();
  }

  handleSearch(): void {
    this.pageIndex = 1;
    this.getHttpLogPage();
  }

  handleDelete(): void {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: HttpLogQo = HttpLogQoData();
      if (this.deleteDate) {
        let deleteDateLt: number = (this.deleteDate as Date).getTime();
        params.timeStampLt = deleteDateLt;
      }
      HttpLogDelete(params).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.handleSearch();
        }
      });
    });
  }

  // 获取表单列表
  getHttpLogPage(): void {
    let params: HttpLogQo = {
      ...this.logSearchModel,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    HttpLogPage(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.logListData = res.data.list;
        this.total = res.data.total;
      }
    });
  }

  showRequestBodyDialog(requestBody: string) {
    this.dialogVisible = true;
    if (!requestBody || requestBody == '') {
      this.requestBodyJson = null;
    }
    this.requestBodyJson = JSON.parse(requestBody);
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getHttpLogPage();
  }
  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getHttpLogPage();
  }
}
